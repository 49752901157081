import React, { useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import type { HitsProvided } from 'react-instantsearch-core'
import dayjs from 'dayjs'
import { useFeature } from '@growthbook/growthbook-react'

import { useSelect } from 'store/index'

import AlgoliaResult from './AlgoliaResult'

import { calculateTotalPriceForHit } from 'utils/Listings'

import dateFormats from 'constants/dates'

import type { AlgoliaListing } from 'types/externalData'

const DEFAULT_STAY_LENGTH = 5

// Custom Hits Component
// Following Algolia documentation so data can be shared
// between the map and search results
const AlgoliaHits = React.memo(({ hits }: HitsProvided<AlgoliaListing>) => {
  const numHits = useSelect((state) => state.search.numHits)
  const startDate = useSelect((state) => state.search.selectedDates?.start)
  const endDate = useSelect((state) => state.search.selectedDates?.end)
  const hasPetsSelected = useSelect((state) => state.search.selectedPets)

  const { on: showMedianPrice } = useFeature('avg-price-v2')

  const getStayLength = () => {
    if (showMedianPrice) {
      return DEFAULT_STAY_LENGTH
    }
    return 0
  }

  const stayLength =
    startDate && endDate
      ? dayjs(endDate, dateFormats.DEFAULT).diff(
          dayjs(startDate, dateFormats.DEFAULT),
          'days',
        )
      : getStayLength()

  const calculateTotalPrice = useCallback(
    (hit: AlgoliaListing) => {
      return calculateTotalPriceForHit(
        hit,
        startDate,
        endDate,
        stayLength,
        hasPetsSelected,
        showMedianPrice,
      )
    },
    [startDate, endDate, hasPetsSelected, stayLength],
  )

  const calculatePage = (index: number) => {
    return Math.ceil((index + 1) / 20)
  }

  useEffect(() => {
    if (numHits === null || (numHits > 0 && !hits.length)) {
      return
    }

    const timer = setTimeout(() => {
      window.analytics.track('Product List Viewed', {
        query: window.location.href.split('?')[1] || '',
        results_count: numHits,
        results_page: calculatePage(hits[0]?.__position || 1),
        products: hits.map((hit) => ({
          product_id: hit.objectID,
          category: hit['Property Type'] || '',
          price: calculateTotalPrice(hit),
          position: hit.__position || 1,
          review_score: hit['Average Rating'] || 0,
          review_count: hit['Number of Reviews'] || 0,
        })),
      })
    }, 800)

    return () => clearTimeout(timer)
  }, [hits, numHits])

  return (
    <>
      {hits.length
        ? hits.map((hit, i) => {
            const totalPrice = calculateTotalPrice(hit)
            const calculateAvgPriceWithFees = () => totalPrice / stayLength

            return (
              <AlgoliaResult
                calculatePrice={calculateAvgPriceWithFees}
                hit={hit}
                key={hit.objectID}
                resultIndex={i}
                totalPrice={totalPrice}
              />
            )
          })
        : null}
    </>
  )
}, isEqual)

AlgoliaHits.displayName = 'AlgoliaHits'

export default AlgoliaHits
